<template>
    <div>
        <v-title v-if="Object.keys(response).length" :title="`${response.data.applicationName} - Applications`"></v-title>

        <title-box>
            <div class="row">
                <div class="col-12">
                    <h1>Edit application:</h1>
                </div>
            </div>
        </title-box>

        <div class="container-fluid">
            <form @submit.prevent="submitForm" v-if="form && hasPermission('MerchantCentral/updateApplication')">
                <div class="row">
                    <div class="col-8">
                        <mercur-card>
                            <h2 class="font-weight-normal">Application details</h2>
                            <mercur-input id="applicationName" v-model="form.applicationName" :class="getValidationClass($v, 'applicationName')">
                                Application Name
                                <template #note>
                                    <span class="form-error" v-if="!$v.form.applicationName.required">Application name is required</span>
                                </template>
                            </mercur-input>
                            <div class="d-flex">
                                <mercur-input class="flex-grow-1" id="applicationSecret" v-model="form.applicationSecret" :class="getValidationClass($v, 'applicationSecret')">
                                    Application Secret
                                    <template #note>
                                        <span class="form-error" v-if="$v.form.applicationSecret.$invalid">Application secret needs to be at least 10 characters</span>
                                    </template>
                                </mercur-input>
                                <mercur-button @click.native="form.applicationSecret = generatePassword($v.form.applicationSecret.$params.minLength.min)" class="mb-3 btn btn-primary btn-icon btn-icon-square"><i class="fas fa-redo"></i></mercur-button>
                            </div>
                        </mercur-card>

                        <div class="text-right">
                            <mercur-button class="btn" to="/applications" :disabled="loading">Cancel</mercur-button>
                            <mercur-button class="btn btn-primary" type="submit" :disabled="loading">Add Application</mercur-button>
                        </div>
                    </div>
                </div>
            </form>
            <span v-else>
                Not allowed to see this view
            </span>
        </div>
    </div>
</template>
<script>
import ResourcesView from '@/views/ResourcesView'
import CONFIG from '@root/config'
import FormMixin from '@/mixins/Form'
import { required, minLength } from 'vuelidate/lib/validators'
import { generatePassword } from '@/utils/helpers'

export default {
    name: 'EditApplication',
    mixins: [ResourcesView, FormMixin],
    data () {
        return {
            url: CONFIG.API.ROUTES.APPLICATIONS.GET.replace('{applicationId}', this.$route.params.applicationId),
            action: CONFIG.API.ROUTES.APPLICATIONS.UPDATE,
            form: null,
            isNotApplicationBound: true,
        }
    },
    validations: {
        form: {
            applicationName: {
                required,
            },
            applicationSecret: {
                required,
                minLength: minLength(10),
            },
        },
    },
    watch: {
        response () {
            this.form = this.response.data
        },
    },
    computed: {
        breadCrumbId () {
            if (!this.form) {
                return ''
            }
            return this.form.applicationName
        },
    },
    methods: {
        generatePassword,
        submitForm () {
            const payload = this.form
            payload.applicationId = this.$route.params.applicationId

            this.submit(this.action, payload).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Application was updated',
                })
                setTimeout(() => {
                    this.$router.push({
                        name: 'ApplicationsWrapper',
                    })
                }, 1000)
            })
        },
    },
}
</script>
